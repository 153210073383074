import React from 'react';
import { Container, Typography, Grid, Paper, makeStyles } from '@material-ui/core';
import Calls from './Calls';
import RoutingRules from './RoutingRules';
import Analytics from './Analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Dashboard: React.FC = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Calls</Typography>
            <Calls />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Routing Rules</Typography>
            <RoutingRules />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Analytics</Typography>
            <Analytics />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
