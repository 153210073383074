import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import api from '../services/api';

const RoutingRules: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null); // Error handling

  useEffect(() => {
    const fetchRules = async () => {
      try {
        const response = await api.get('/routing-rules');
        setRules(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Failed to fetch routing rules:', error);
        setError('Failed to load routing rules. The API may not be running.'); // Set error message
      } finally {
        setLoading(false);
      }
    };
    fetchRules();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>; // Display error message
  }

  return (
    <div>
      {rules.length > 0 ? (
        rules.map((rule) => (
          <Typography key={rule.ID}>
            Rule: {rule.Name} (Active: {rule.IsActive ? 'Yes' : 'No'})
          </Typography>
        ))
      ) : (
        <Typography>No routing rules available.</Typography>
      )}
    </div>
  );
};

export default RoutingRules;
