import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import api from '../services/api';

const Calls: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [calls, setCalls] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null); // Error handling

  useEffect(() => {
    const fetchCalls = async () => {
      try {
        const response = await api.get('/calls');
        setCalls(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Failed to fetch calls:', error);
        setError('Failed to load calls. The API may not be running.'); // Set error message
      } finally {
        setLoading(false);
      }
    };
    fetchCalls();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>; // Display error message
  }

  return (
    <div>
      {calls.length > 0 ? (
        calls.map((call) => (
          <Typography key={call.ID}>
            Call from {call.CallerID} to {call.ReceiverID} (Duration: {call.Duration} seconds)
          </Typography>
        ))
      ) : (
        <Typography>No calls available.</Typography>
      )}
    </div>
  );
};

export default Calls;
